import { CardBody, CardBodyProps } from "./CardBody";

import { PropsWithChildren } from "react";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";

export type ClickableContactCardProps = {
  handleClick: Function;
  disabled: boolean;
  className?: string;
  buttonClassName?: string;
  iconBackgroundColour?: string;
  iconForegroundColour?: string;
} & CardBodyProps &
  PropsWithChildren;

export const ClickableRoundedCard = ({ handleClick, disabled, className, buttonClassName, children, ...rest }: ClickableContactCardProps) => {
  return (
    <RoundedCard className={className}>
      <button className={`flex flex-col h-full text-left w-full ${buttonClassName}`} disabled={disabled} onClick={() => handleClick()}>
        <CardBody {...rest} />
      </button>
      {children}
    </RoundedCard>
  );
};

ClickableRoundedCard.defaultProps = { showChevron: true, disabled: false };
