import CallUsBlock from "./Components/CallUsBlock/CallUsBlock";
import { ReactComponent as CallbackIcon } from "shared/assets/Icons/request-contact.svg";
import { ClickableRoundedCard } from "./Components/ContactCard/ClickableRoundedCard";
import { ContactCardWithButton } from "./Components/ContactCard/ContactCardWithButton";
import { EventNames } from "core/monitoring/types/enums";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { ReactComponent as LiveChatIcon } from "shared/assets/Icons/contact-us.svg";
import { ReactComponent as LiveChatUnavailableIcon } from "shared/assets/Icons/contact-us-unavailable.svg";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { ProtectedModule } from "core/auth/ProtectedModule";
import { ReportingEventType } from "shared/constants/ReportingEventType";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { SafeSpaces } from "../Home/components/SafeSpaces/SafeSpaces";
import { UserState } from "core/state/userState";
import { ReactComponent as VideoCallIcon } from "shared/assets/Icons/video-call.svg";
import { ReactComponent as VideoCallUnavailableIcon } from "shared/assets/Icons/video-call-unavailable.svg";
import { WisdomButtonCard } from "./Components/WisdomButtonCard";
import { WisdomLiteNotice } from "./Components/WisdomLiteNotice";
import WisdomModules from "core/Modules";
import { dial } from "core/javascriptChannels";
import helplineNumbersJson from "shared/assets/json/helplineNumbers.json";
import { isAU } from "shared/core/locale";
import { removeSpaces } from "shared/core/utils/string";
import { t } from "i18next";
import { trackEvents } from "core/monitoring/Events";
import { useLiveChat } from "./Components/LiveChatProvider";
import useLocale from "core/hooks/useLocale/useLocale";
import { useLogReportingEventFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "core/state/UserContext";
import { useVideoCall } from "./hooks/useVideoCall";

type HelplineNumber = { country: string; number: string };

const helplineNumbers: HelplineNumber[] = helplineNumbersJson;

const parseHelplineNumbers = (inputString: string): HelplineNumber[] => {
  const parsedNumbers: HelplineNumber[] = [];
  const normalizedInput = removeSpaces(inputString);

  for (const { number, country } of helplineNumbers) {
    const normalizedNumber = removeSpaces(number);
    const regex = new RegExp(normalizedNumber, "g");

    if (regex.test(normalizedInput)) {
      parsedNumbers.push({ country, number });
      inputString = inputString.split(regex).join("");
    }
  }

  return parsedNumbers;
};

export const ContactUs = () => {
  const user = UserState.get();
  const helplineData = user?.helplinePhoneNumber ?? "";
  const parsedHelplineNumbers = parseHelplineNumbers(helplineData);
  const navigate = useNavigate();
  const { trigger } = useLogReportingEventFunctionRequest();
  const { isChatAvailable, startChat } = useLiveChat();
  const { isVideoCallAvailable, startVideoCall } = useVideoCall();
  const { isMobileApp } = useMobilePlatform();
  const { localeId } = useLocale();
  const { isPermitted } = useUserContext();
  const handleStartPhoneCall = (number: string) => {
    trackEvents(EventNames.PHONE_CALL_START);
    trigger({ body: { reportingEventType: ReportingEventType.SupportRequestPhone } }).finally(() => dial(removeSpaces(number)));
  }
  const isWisdomLite = (
    (!isPermitted(WisdomModules.requestCallBack) &&
      !isPermitted(WisdomModules.liveChat) &&
      !isPermitted(WisdomModules.videoChat) &&
      !isPermitted(WisdomModules.helpline)) ||
    (isAU(localeId) &&
      !isPermitted(WisdomModules.requestCallBack) &&
      !isPermitted(WisdomModules.helpline))
  );

  return (
    <>
      <PageTitle title={t("pageTitle.contactUs.home")} />
      <div className="mt-6">
        <h2>{t("contactUs.heading")}</h2>
        <p className="col-start-1">
          {t("contactUs.headingParagraph")}
          {!isWisdomLite ? t("contactUs.headingParagraphOptions") : ""}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <ProtectedModule
            wisdomModule={WisdomModules.liveChat}
            moduleComponent={
              <Hideable hidden={isAU(localeId)}>
                <div className="py-4 md:order-1 grid-span-1 md:p-4">
                  <ClickableRoundedCard
                    className="h-full"
                    title={t("contactUs.liveChat.title")}
                    icon={isChatAvailable ? <LiveChatIcon /> : <LiveChatUnavailableIcon />}
                    description={isChatAvailable ? t("contactUs.liveChat.description") : t("contactUs.liveChat.descriptionOffline")}
                    disabled={!isChatAvailable}
                    showChevron={isChatAvailable}
                    handleClick={() =>
                      trigger({ body: { reportingEventType: ReportingEventType.SupportRequestLiveChat } }).finally(() => startChat())
                    }
                  />
                </div>
              </Hideable>
            }
          />
          <Hideable hidden={isAU(localeId)}>
            <ProtectedModule
              wisdomModule={WisdomModules.videoChat}
              moduleComponent={
                <div className="py-4 md:order-2 grid-span-1 md:p-4">
                  <ClickableRoundedCard
                    className="h-full"
                    title={t("contactUs.videoCall.title")}
                    icon={isVideoCallAvailable ? <VideoCallIcon /> : <VideoCallUnavailableIcon />}
                    description={isVideoCallAvailable ? t("contactUs.videoCall.description") : t("contactUs.videoCall.descriptionOffline")}
                    handleClick={() =>
                      trigger({ body: { reportingEventType: ReportingEventType.SupportRequestVideoCall } }).finally(startVideoCall)
                    }
                    showChevron={isVideoCallAvailable}
                    disabled={!isVideoCallAvailable}
                  />
                </div>
              }
            />
          </Hideable>
          <ProtectedModule
            wisdomModule={WisdomModules.helpline}
            moduleComponent={(<> {parsedHelplineNumbers && parsedHelplineNumbers.length > 0 && (
              <div className="w-full py-4 md:order-5 grid-span-1 md:p-4">
                <h4 className="ml-5 font-semibold text-black">{t("contactUs.callUs.description")}</h4>
                <div className="flex flex-col gap-4">
                  {parsedHelplineNumbers.map(({ number, country }: HelplineNumber, index: number) => {
                    return (
                      number && (
                        <RoundedCard key={index}>
                          <button
                            onClick={isMobileApp ? () => handleStartPhoneCall(number) : undefined}
                            disabled={!isMobileApp}
                            className="flex items-center"
                          >
                            <CallUsBlock code={country} number={number} />
                          </button>
                        </RoundedCard>
                      )
                    );
                  })}
                </div>
              </div>
            )}</>)} />
          <ProtectedModule
            wisdomModule={WisdomModules.requestCallBack}
            moduleComponent={
              <div className="w-full h-full py-4 md:order-3 md:p-4">
                <ContactCardWithButton
                  buttonClassName="h-full"
                  buttonText={t("contactUs.callback.title")}
                  buttonColour="primary"
                  handleClick={() =>
                    trigger({ body: { reportingEventType: ReportingEventType.SupportRequestRequestCallback } }).finally(() =>
                      navigate("/contact-us/callback")
                    )
                  }
                  icon={<CallbackIcon />}
                  description={t("contactUs.callback.description")}
                />
              </div>
            }
          />
          <Hideable
            hidden={!isWisdomLite}
          >
            <WisdomLiteNotice />
          </Hideable>

          <Hideable hidden={isAU(localeId)}>
            <div className="w-full py-4 md:order-4 md:px-4">
              <WisdomButtonCard />
            </div>
          </Hideable>
          <Hideable hidden={isAU(localeId)}>
            <div className="w-full py-4 md:order-last md:px-4">
              <SafeSpaces />
            </div>
          </Hideable>
        </div>
      </div>
    </>
  );
};
