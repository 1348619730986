import "./index.css";
import "./i18n"; // Bootstrap internationalisation
import "shared/core/types/authStorage";

import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { ErrorFallback, handleError } from "./ErrorFallback";
import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { appInsights, appInsightsLogger, initialiseAppInsights, reactPlugin, sendToAppInsights, withAppInsights } from "core/monitoring/AppInsights";
import { getAppVersion, getDeviceInfo, getMobilePlatfrom } from "core/javascriptChannels";

import { App } from "./App";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";
import ReactDOM from "react-dom/client";
import { TokenRenewalContextProvider } from "core/auth/TokenRenewalContext";
import { UserContextProvider } from "core/state/UserContext";
import { initialiseGTM } from "core/monitoring/GoogleAnalytics";
import reportWebVitals from "./reportWebVitals";

const fetchDeviceDetails = async () => {
  const [appVersion, deviceInfo, platform] = await Promise.all([getAppVersion(), getDeviceInfo(), getMobilePlatfrom()]);
  return {
    appVersion,
    deviceInfo,
    platform,
  };
};

try {
  fetchDeviceDetails().then(({ appVersion, deviceInfo, platform }) => {
    initialiseAppInsights({
      Platform: !!window.flutter_inappwebview ? platform : "web",
      ...(!!window.flutter_inappwebview && {
        AppVersion: appVersion ?? "",
        DeviceInfo: deviceInfo ?? "",
      }),
    });

    (process.env.REACT_APP_GTM_ID || process.env.REACT_APP_AU_GTM_ID) && initialiseGTM();
    Log.setLogger(appInsightsLogger);

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    const redirectUri = `${window.location.origin}/auth`;
    window.authStorage = !!window.flutter_inappwebview ? localStorage : sessionStorage;
    const userManagerSettings: UserManagerSettings = {
      authority: process.env.REACT_APP_AUTH_AUTHORITY!,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
      redirect_uri: redirectUri,
      automaticSilentRenew: true,
      silent_redirect_uri: redirectUri,
      scope: "offline_access openid password:change",
      post_logout_redirect_uri: `${window.location.origin}/login`,
      userStore: new WebStorageStateStore({
        store: window.authStorage,
      }),
    };
    const userManager = new UserManager(userManagerSettings);
    const authProviderConfig: AuthProviderProps = {
      userManager,
      onSigninCallback: (_user: User | void): void => {
        window.history.replaceState({}, document.title, window.location.pathname);
      },
    };

    const AppWithTracking = withAppInsights(App);

    console.log("Initialisation of auth finished, trying to render React App");

    root.render(
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} onReset={() => (window.location.href = "/home")}>
          <BrowserRouter>
            <AuthProvider {...authProviderConfig}>
              <AppInsightsContext.Provider value={reactPlugin}>
                <TokenRenewalContextProvider userManager={userManager}>
                  <UserContextProvider>
                    <AppWithTracking />
                  </UserContextProvider>
                </TokenRenewalContextProvider>
              </AppInsightsContext.Provider>
            </AuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </React.StrictMode>
    );

    reportWebVitals(sendToAppInsights);
  });
} catch (err: any) {
  appInsights.trackException({ exception: err, severityLevel: 3 });
  console.error(err);
}
