import { CopyBlock } from "shared/UI/CopyBlock/CopyBlock";
import { ReactComponent as DownChevron } from "shared/assets/Icons/down.svg";
import { ModuleInfo } from "../interfaces";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import classNames from "classnames";
import { useState } from "react";

export const ModuleInfoAccordion = (info: ModuleInfo) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const { title, description, steps, stepBannerInsert, endNote } = info;
  const handleClickAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  return (
    <div
      data-testid="account-security-module-info"
      className="bg-[#244A68] w-full text-white px-5 md:px-10 py-5 mt-5 rounded-b-lg shadow-black drop-shadow-md"
    >
      <button className="flex items-center justify-between w-full space-x-2" onClick={handleClickAccordion}>
        <p className="font-black text-left text-white">{title}</p>
        <div
          className={classNames("duration-200 w-5 h-5 md:w-10 md:h-10", {
            "rotate-180": openAccordion,
          })}
        >
          <DownChevron style={{ width: "inherit", height: "inherit" }} />
        </div>
      </button>
      {openAccordion && (
        <div data-testid="info-accordion-body" className="mt-5 space-y-6 ">
          <p>{description}</p>
          {steps.map((step) => (
            <CopyBlock content={step} />
          ))}
          {stepBannerInsert && (
            <RoundedCard>
              <CopyBlock content={stepBannerInsert} className="text-black" />
            </RoundedCard>
          )}
          <CopyBlock content={endNote} className="text-white" />
        </div>
      )}
    </div>
  );
};
