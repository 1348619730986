import "./ErrorAlert.css";

import Alert from "./Alert";
import { Hideable } from "../Hideable/Hideable";
import { ReactElement } from "react";

export type ErrorAlertProps = {
  content: string;
  title?: string;
};

export const ErrorAlert = ({ content, title = "", ...rest }: ErrorAlertProps): ReactElement => {
  return (
    <Hideable hidden={content.trim().length === 0 && title.trim().length === 0}>
      <Alert icon="error" size="base" title={title} type="error" {...rest}>
        <p className="message">{content}</p>
      </Alert>
    </Hideable>
  );
};

export default ErrorAlert;
