import { NavLink, useNavigate } from "react-router-dom";

import { ReactComponent as AccountAvatarIcon } from "shared/assets/Icons/account-avatar.svg";
import { EqualWebButton } from "UIPalette/EqualWebButton/EqualWebButton";
import HealthAssuredLogo from "shared/assets/HealthAssuredLogo.png";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { SearchBar } from "./SearchBar";
import classNames from "classnames";
import { getChangePasswordUrl } from "shared/core/hooks/getChangePasswordUrl";
import { t } from "i18next";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import { useLogout } from "../../../hooks/Logout/useLogout";
import { useRedirect } from "shared/core/hooks/useRedirect";

export const TopNavBar = () => {
  const navigateHook = useNavigate();
  const logout = useLogout();
  const redirectHook = useRedirect();
  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const showAccountSecurity = !!process.env.REACT_APP_SHOW_ACCOUNT_FEATURE_TOGGLE;

  const changePasswordUrl = getChangePasswordUrl(
    process.env.REACT_APP_AUTH_AUTHORITY!,
    process.env.REACT_APP_AUTH_CLIENT_ID!,
    `${window.location.origin}/auth`
  );

  return (
    <header role="banner" ref={targetAreaRef} className="flex flex-row bg-white h-20 w-full sticky top-0 z-OVERLAY drop-shadow-md md:px-[4rem]">
      <NavLink
        className="flex items-center flex-grow w-4/6 h-full px-3 lg:flex-grow-0 xs:pointer-events-none lg:pointer-events-auto sm:w-auto"
        aria-label={t("navigation.homeNavigationLabel")}
        to={"/home"}
      >
        <img alt={t("navigation.healthAssuredlogo")} src={HealthAssuredLogo} className="h-full max-h-14" />
      </NavLink>
      <div className="flex-row items-center flex-grow hidden h-full gap-6 ml-4 lg:inline-flex">
        <NavLink
          to={"/health-hub"}
          aria-label={t("navigation.healthHubNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.healthHubButton")}
        </NavLink>
        <NavLink
          to={"/contact-us"}
          aria-label={t("navigation.contactUsNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.contactUsButton")}
        </NavLink>
        <NavLink
          to={"/resources"}
          aria-label={t("navigation.resourcesNavigationLabel")}
          className={({ isActive }) => classNames("text-xl font-bold text-primary-700", { "no-underline": !isActive })}
        >
          {t("navigation.resourcesButton")}
        </NavLink>
      </div>
      <EqualWebButton />
      <SearchBar />
      <button
        className="hidden h-full mt-0 mr-5 align-top lg:inline-block"
        aria-label={t("navigation.userAccountMenu.ariaUserMenu")}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <AccountAvatarIcon />
      </button>
      <Hideable hidden={!isComponentVisible}>
        <div className="absolute flex-col hidden w-48 overflow-hidden bg-white rounded shadow-sm lg:block right-5 top-20 z-OVERLAY">
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.personalDetails")}
            onClick={() => navigateHook("/account/personal-details")}
          >
            <span>{t("navigation.userAccountMenu.personalDetails")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.preferences")}
            onClick={() => navigateHook("/account/preferences")}
          >
            <span>{t("navigation.userAccountMenu.preferences")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.about")}
            onClick={() => navigateHook("/account/about")}
          >
            <span>{t("navigation.userAccountMenu.about")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.terms")}
            onClick={() => navigateHook("/account/terms")}
          >
            <span>{t("navigation.userAccountMenu.terms")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.privacy")}
            onClick={() => navigateHook("/account/privacy")}
          >
            <span>{t("navigation.userAccountMenu.privacy")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.cookiePolicy")}
            onClick={() => navigateHook("/account/cookie-policy")}
          >
            <span>{t("navigation.userAccountMenu.cookiePolicy")}</span>
          </button>
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.contactSupport")}
            onClick={() => navigateHook("/account/contact-support")}
          >
            <span>{t("navigation.userAccountMenu.contactSupport")}</span>
          </button>
          {
            showAccountSecurity ? <button
              className="w-full h-full p-2 text-left hover:bg-silver"
              aria-label={t("navigation.userAccountMenu.accountSecurity")}
              onClick={() => navigateHook("/account/account-security")}
            >
              <span>{t("navigation.userAccountMenu.accountSecurity")}</span>
            </button> : <button
              className="w-full h-full p-2 text-left hover:bg-silver"
              aria-label={t("navigation.userAccountMenu.changePassword")}
              onClick={() => redirectHook(changePasswordUrl)}
            >
              <span>{t("navigation.userAccountMenu.changePassword")}</span>
            </button>
          }
          <button
            className="w-full h-full p-2 text-left hover:bg-silver"
            aria-label={t("navigation.userAccountMenu.logout")}
            onClick={() => logout()}
          >
            <span>{t("navigation.userAccountMenu.logout")}</span>
          </button>
        </div>
      </Hideable>
    </header>
  );
};
