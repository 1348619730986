import "shared/extensions/string";

import { PropsWithChildren, ReactElement } from "react";

import { Navigate } from "react-router-dom";

interface FeatureToggleProps extends PropsWithChildren {
  showFeature: boolean;
}

export const FeatureToggle = ({ children, showFeature }: FeatureToggleProps): ReactElement => {
  if (!showFeature) {
    return <Navigate to="/not-found" />;
  }

  return <>{children}</>;
};
