import { AppRoutes } from "core/AppRoutes"
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner"
import { Form } from "./Components/Form/Form";
import { PageHeader } from "UIPalette/PageHeader/PageHeader"
import PageTitle from "UIPalette/PageTitle/PageTitle"
import { useTranslation } from "react-i18next";

export const PasswordManagement = () => {
  const { t } = useTranslation();
  return <>
    <PageTitle title={t("pageTitle.account.accountSecurity.passwordManagement")} />
    <CurvedTopBanner>
      <PageHeader title={t("passwordManagement.title")} backRoute={AppRoutes.accountSecurity} />
    </CurvedTopBanner>
    <Form />
  </>
}