import { Controller, useFormState, useWatch } from "react-hook-form";
import { InputFields, ValidationRules } from "./interfaces";
import { useEffect, useRef, useState } from "react";

import Alert from "shared/UI/Alerts/Alert";
import { AxiosError } from "axios";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { EventNames } from "core/monitoring/types/enums";
import { PasswordTextbox } from "shared/UI/PasswordTextbox/PasswordTextbox";
import { PasswordValidationRules } from "../PasswordValidationRules/PasswordValidationRules";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import changePassword from "shared/request/changePassword";
import { isObjectEmpty } from "core/helpers/utils";
import { trackEvents } from "core/monitoring/Events";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const defaultValues: InputFields = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
};

export const Form = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "passwordManagement" });
  const alertsBlockRef = useRef<HTMLDivElement>(null);

  const {
    control,
    setError,
    reset,
    clearErrors,
    handleSubmit: handleFormSubmit,
    resetField
  } = useForm<InputFields>({
    criteriaMode: 'all',
    mode: "onChange",
    defaultValues,
  });

  const { dirtyFields, errors, isDirty, isSubmitted, isSubmitSuccessful } = useFormState({ control });

  const [newPassword, currentPassword] = useWatch({ name: ["newPassword", "currentPassword"], control })

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isObjectEmpty(errors) && autoScrollEnabled) {
      asyncScrollToAlerts();
    }
  }, [autoScrollEnabled, errors, errors.root, errors.confirmPassword, errors.currentPassword, errors.newPassword]);

  async function handleSubmit() {
    trackEvents(EventNames.PASSWORD_CHANGE_SUBMIT);
    setIsLoading(true);
    try {
      await changePassword({ currentPassword, newPassword });
      reset(defaultValues);
      asyncScrollToAlerts();
    } catch (_e) {
      const e = _e as AxiosError<{ detail: string }>;
      const message = e?.response?.data?.detail;
      const status = e?.response?.status;

      if (status === 409) {
        setError('root.serverError', { message });
      } else {
        setError('root.serverError', { message: t("errors.generic") });
      }
      appInsightsLogger.error("Failed to request password change", _e);
      resetConfirmPassword();
    }
    setIsLoading(false)
  }

  const handleFocus = () => {
    if (isSubmitSuccessful) {
      reset();
    }
    if (errors.root) {
      clearErrors();
    }
    setAutoScrollEnabled(false);
  }

  const asyncScrollToAlerts = () => {
    setTimeout(() => {
      if (alertsBlockRef?.current) {
        alertsBlockRef!.current!.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }

  const resetConfirmPassword = () => resetField("confirmPassword", { keepError: true });

  const alerts: { show: boolean; content: string }[] = [
    {
      show: isSubmitted && !!errors?.root?.serverError.message,
      content: errors?.root?.serverError.message || ""
    },
    {
      show: isSubmitted && !errors?.newPassword && !!errors?.confirmPassword?.message,
      content: errors?.confirmPassword?.message || ""
    },
    {
      show: isSubmitted && !!errors?.newPassword?.message,
      content: errors?.newPassword?.message || ""
    }
  ];

  const validationRules: ValidationRules = [
    {
      testId: 'oneUppercaseValidationIndicator',
      errorKey: 'oneUppercase',
      translationKey: 'rules.oneUppercase'
    },
    {
      testId: 'numberOrSymbolValidationIndicator',
      errorKey: 'numberOrSymbol',
      translationKey: 'rules.oneNumberSymbol'
    },
    {
      testId: 'minLengthValidationIndicator',
      errorKey: 'minLength',
      translationKey: 'rules.minLength'
    }
  ]

  const isValidForRequest = dirtyFields?.currentPassword && dirtyFields?.newPassword && dirtyFields?.confirmPassword && !errors?.currentPassword && !errors?.newPassword?.types?.oneUppercase && !errors?.newPassword?.types?.numberOrSymbol && !errors?.newPassword?.types?.minLength;

  return <form data-testid="password-management-form" onSubmit={handleFormSubmit(handleSubmit, resetConfirmPassword)}>
    <div className="flex flex-col gap-4 mx-auto w-full md:w-[600px] scroll-smooth">
      <div>
        <h3>{t("currentPassword")}</h3>
        <Controller
          name={"currentPassword"}
          control={control}
          rules={{
            validate: {
              required: (v: string) => v !== "" || t("currentPasswordRequired"),
            }
          }}
          render={({ field: { onChange, value } }) => <PasswordTextbox aria-label={t("currentPasswordPlaceholder")} onFocus={handleFocus} value={value} name={"currentPassword"} onChange={onChange} placeholder={t("currentPasswordPlaceholder")} />}
        />
      </div>
      <div>
        <h3>{t("passwordRules")}</h3>
        <PasswordValidationRules dirtyFields={dirtyFields} errors={errors} validationRules={validationRules} />
      </div>
      <div>
        <h3>{t("newPassword")}</h3>
        <Controller
          name={"newPassword"}
          control={control}
          rules={{
            validate: {
              required: (v: string) => v !== "" || t("newPasswordRequired"),
              oldPassword: (v: string) => v !== currentPassword || t("errors.oldPassword"),
              numberOrSymbol: (v: string) => /^.*[0-9\W_].*$/.test(v),
              minLength: (v: string) => v.length >= 12,
              oneUppercase: (v: string) => /^(?=.*[A-Z]).*$/.test(v),
            }
          }}
          render={({ field: { onChange, value } }) => <PasswordTextbox aria-label={t("newPasswordPlaceholder")} onFocus={handleFocus} value={value} name={"newPassword"} onChange={onChange} placeholder={t("newPasswordPlaceholder")} />}
        />
      </div>
      <div>
        <h3>{t("confirmPassword")}</h3>
        <Controller
          name={"confirmPassword"}
          control={control}
          rules={{
            validate: {
              required: (v: string) => v !== "" || t("passwordRequired"),
              matched: (v: string) => v === newPassword || t("passwordsNotMatched")
            },
          }}
          render={({ field: { onChange, value } }) => <PasswordTextbox aria-label={t("confirmPasswordPlaceholder")} onFocus={handleFocus} value={value} name={"confirmPassword"} onChange={onChange} placeholder={t("confirmPasswordPlaceholder")} />}
        />
      </div>
      <StylableButton
        className="[&>*]:mt-6"
        color="primary"
        type="submit"
        disabled={!isDirty || !isValidForRequest || isLoading}
        size={"xl"}
        text={t("button")}
        aria-label={t("buttonLabel")}
        onClick={() => setAutoScrollEnabled(true)}
      />
      <div ref={alertsBlockRef}>
        {alerts.map((alert, index) =>
          alert.show && <ErrorAlert key={index} content={alert.content} />
        )}
        {!isDirty && isSubmitSuccessful && (
          <Alert size="base" type="success" className="!rounded">
            <p className="text-white message">{t("successMessage")}</p>
          </Alert>
        )}
      </div>
    </div>
  </form >
}