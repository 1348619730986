import { AccountSecurityModule } from "../interfaces";
import { ModuleInfoAccordion } from "../ModuleInfoAccordion/ModuleInfoAccordion";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import StylableButton from "shared/UI/Buttons/StylableButton";
import useIsMobile from "shared/core/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

export const AccountSecurityModuleCard = ({ title, description, ctaText, ctaLink, info, Icon }: AccountSecurityModule) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <RoundedCard padding="p-0" className="text-lg lg:text-xl">
      <div className="flex flex-col-reverse justify-between p-5 md:items-center md:space-x-10 md:p-10 md:flex-row">
        <div className="flex-col justify-between max-w-4xl mt-3 space-y-8 bg-indigo-500 md:mt-0">
          <div className="flex-1">
            <h3 className="cardTitle">{title}</h3>
            <p>{description}</p>
          </div>
          <div className="flex-1 mt-8">
            <StylableButton
              className="w-full orange-button padding-xl"
              fullWidth={isMobile}
              text={ctaText}
              aria-label={title}
              size="xl"
              onClick={() => navigate(ctaLink)}
            />
          </div>
        </div>
        {Icon && (
          <div data-testid="account-security-module-icon" className="w-20 h-20 md:w-32 md:h-32 lg:w-48 lg:h-48">
            <Icon style={{ width: "inherit", height: "inherit" }} />
          </div>
        )}
      </div>
      {info && <ModuleInfoAccordion {...info} />}
    </RoundedCard>
  );
};
