import { client } from "../core/clients/brightAuthClient";
import { setAuthHeaders } from "../core/hooks/headers";

type PasswordChangeBody = { currentPassword: string; newPassword: string };

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;

const changePassword = async ({ currentPassword, newPassword }: PasswordChangeBody) => {
  const headers = {};
  let axiosConfig = {};
  axiosConfig = setAuthHeaders(true, { headers, ...axiosConfig });
  const uri = "/v1/account/password";
  const body = { currentPassword, newPassword, clientId };
  await client.post(uri, body, axiosConfig);
};

export default changePassword;
