import { AccountNavButton, AccountNavLink } from "../../Components/AccountNavItem";

import { ReactComponent as AboutIcon } from "shared/assets/Icons/ha-smiley.svg";
import { ReactComponent as ChangePasswordIcon } from "shared/assets/Icons/change-password.svg";
import { ReactComponent as ContactIcon } from "shared/assets/Icons/contact.svg";
import { ReactComponent as InfoIcon } from "shared/assets/Icons/info.svg";
import { ReactComponent as LogoutIcon } from "shared/assets/Icons/logout.svg";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { ReactComponent as PersonalDetailsIcon } from "shared/assets/Icons/account.svg";
import { ReactComponent as PreferencesIcon } from "shared/assets/Icons/settings.svg";
import { getChangePasswordUrl } from "shared/core/hooks/getChangePasswordUrl";
import { useLogout } from "../../../../hooks/Logout/useLogout";
import { useRedirect } from "shared/core/hooks/useRedirect";
import { useTranslation } from "react-i18next";

export const AccountHome = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const redirectHook = useRedirect();

  const showAccountSecurity = !!process.env.REACT_APP_SHOW_ACCOUNT_FEATURE_TOGGLE;

  const changePasswordUrl = getChangePasswordUrl(
    process.env.REACT_APP_AUTH_AUTHORITY!,
    process.env.REACT_APP_AUTH_CLIENT_ID!,
    `${window.location.origin}/auth`
  );

  return (
    <>
      <PageTitle title={t("pageTitle.account.home")} />
      <div className="mt-6 col-span-full">
        <h2>{t("account.title")}</h2>
        <AccountNavLink to={"/account/personal-details"} text={t("account.personalDetails")} Icon={PersonalDetailsIcon} />
        <AccountNavLink to={"/account/preferences"} text={t("account.preferences")} Icon={PreferencesIcon} />
        <AccountNavLink to={"/account/about"} text={t("account.about")} Icon={AboutIcon} />
        <AccountNavLink to={"/account/terms"} text={t("account.terms")} Icon={InfoIcon} />
        <AccountNavLink to={"/account/privacy"} text={t("account.privacy")} Icon={InfoIcon} />
        <AccountNavLink to={"cookie-policy"} text={t("account.cookiePolicy")} Icon={InfoIcon} />
        <AccountNavLink to={"/account/contact-support"} text={t("account.contactSupport")} Icon={ContactIcon} />
        {showAccountSecurity ? <AccountNavLink to={"/account/account-security"} text={t("account.accountSecurity")} Icon={ChangePasswordIcon} /> : <AccountNavButton onClick={() => redirectHook(changePasswordUrl)} text={t("account.changePassword")} Icon={ChangePasswordIcon} />}
        <AccountNavButton onClick={logout} text={t("account.logOut")} Icon={LogoutIcon} bottomLine />
      </div>
    </>
  );
};
