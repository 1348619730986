import { CardBody, CardBodyProps } from "./CardBody";
import { CardWithButton, CardWithButtonProps } from "UIPalette/CardWithButton/CardWithButton";

export type ContactCardWithButtonProps = CardWithButtonProps & CardBodyProps;

export const ContactCardWithButton = ({
  buttonClassName,
  buttonText,
  handleClick,
  icon,
  title,
  description,
  showChevron,
  buttonColour,
}: ContactCardWithButtonProps) => {
  return (
    <>
      <CardWithButton className="flex flex-col justify-between h-full" buttonColour={buttonColour} buttonClassName={buttonClassName} buttonText={buttonText} handleClick={() => handleClick()}>
        <CardBody showChevron={showChevron} icon={icon} title={title} description={description} />
      </CardWithButton>
    </>
  );
};

ContactCardWithButton.defaultProps = { title: "", showChevron: false };
