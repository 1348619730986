import "shared/extensions/date";

import { AccountSecurityModule } from "../../Components/Modules/interfaces";
import { AccountSecurityModuleCard } from "../../Components/Modules/ModuleCard/ModuleCard";
import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { accountSecurityModulesData } from "../../Components/Modules/data";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { useTranslation } from "react-i18next";

export const AccountSecurityHome = () => {
  const { t } = useTranslation();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);

  return (
    <>
      <PageTitle title={t("pageTitle.account.accountSecurity.home")} />
      <CurvedTopBanner>
        <PageHeader title={t("accountSecurity.heading")} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <div className="flex flex-col space-y-12">
        {accountSecurityModulesData.map((data: AccountSecurityModule) => (
          <AccountSecurityModuleCard key={data.ctaLink} {...data} />
        ))}
      </div>
    </>
  );
};
