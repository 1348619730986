import "./PasswordTextbox.css";

import { ReactComponent as PasswordHiddenIcon } from "shared/assets/Icons/eye.svg";
import { ReactComponent as PasswordVisibleIcon } from "shared/assets/Icons/eye-crossed-out.svg";
import { useState } from "react";

export type PasswordTextboxProps = {
  name: string;
  placeholder: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  value?: string;
};

export const PasswordTextbox = ({
  onChange,
  onFocus,
  ...rest
}: PasswordTextboxProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
    return null;
  }

  const PasswordVisibilityIcon = passwordVisible ? PasswordVisibleIcon : PasswordHiddenIcon;

  return (
    <>
      <div className="relative w-full labelled">
        <input
          type={passwordVisible ? "textbox" : "password"}
          className="w-full px-4 outline-none text-neutral-700 placeholder:font-normal placeholder:text-neutral-400 border-neutral-300 focus-visible:border-primary-300"
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          {...rest}
        />
        <div className="absolute top-0 right-0 flex items-center w-12 h-full">
          {<PasswordVisibilityIcon aria-label={passwordVisible ? "Hide password button" : "Show password button"} onClick={() => togglePasswordVisibility()} className="h-6" />}
        </div>
      </div>
    </>
  );
};
