export enum EventNames {
  CARD_ONCLICK = "card_click",
  BTN_ONCLICK = "btn_click",
  CHART_FILTER = "chart_filter",
  SAFE_SPACE_CLICK = "safe_spaces_click",
  WISDOM_AI_CLICK = "wisdom_AI_click",
  BRIGHT_EX_CLICK = "bright_exchange_click",
  PHONE_CALL_START = "start_phone_call",
  LIVE_CHAT_START = "start_live_chat",
  LIVE_CHAT_END = "end_live_chat",
  VIDEO_CHAT_START = "start_video_chat",
  VIDEO_CHAT_END = "end_video_chat",
  RESOURCE_FILTER = "resource_filter",
  FORM_SUBMIT = "form_submit",
  NAV_SEARCH = "nav_search",
  BREATHING_EXERCISE_START = "start_breathing_exercise",
  BREATHING_EXERCISE_COMPLETE = "complete_breathing_exercise",
  MINI_HEALTH_CHECK_START = "start_mini_health",
  MINI_HEALTH_CHECK_END = "complete_mini_health",
  HEALTH_DATA_SAVE = "save_health_data",
  SAVE_HEALTH_DATA_GOAL = "save_health_data_goal",
  PREVIEW_LEADERBOARD = "preview_leaderboard",
  PASSWORD_CHANGE_SUBMIT = "password_change_submit",
  PASSWORD_CHANGE_OPEN = "password_change_open",
}
